import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae8712a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "position-absolute rounded-pill",
  style: {"font-size":"10px","top":"0","left":"90%","background-color":"red","color":"white","transform":"translate(-50%, -50%)","width":"40px","height":"17px","display":"flex","align-items":"flex-end","justify-content":"center","padding-bottom":"2px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.menuList, (menu) => {
    return (_openBlock(), _createElementBlock("div", {
      key: menu.MENU_NAME,
      class: "d-flex flex-row"
    }, [
      _createVNode(_component_v_btn, {
        onClick: ($event: any) => ($setup.navigateToMenu(menu.MENU_URL)),
        variant: "plain",
        ripple: false,
        class: _normalizeClass(["gnb", { 'gnb-active': $setup.isActive(menu.MENU_URL) }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(menu.MENU_NAME) + " ", 1 /* TEXT */),
          (menu.MENU_NAME == 'Dot Book')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Beta "))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "class"])
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}
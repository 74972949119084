import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e166926"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "side-nav" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "side-nav"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 3,
  class: "side-nav"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("Firmware Update")), 1 /* TEXT */),
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: "/update/firmware/connect",
            class: _normalizeClass(["link", {
              'router-link-active': _ctx.$route.path.includes(
                '/update/firmware/latest'
              ),
            }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("USB Update")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ]),
        _createCommentVNode(" <li> * 블루투스버전 추후 화면 기획시 적용 *\n          <router-link to=\"/bluetooth\" class=\"link\">{{\n            $t(\"Bluetooth Update\")\n          }}</router-link>\n        </li> ")
      ]),
      true
        ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.$t("VOC")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      true
        ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
            false
              ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    class: "link",
                    to: "/"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Bug Fixes")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                class: "link",
                to: "/voc"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Voice of Customer")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                class: "link",
                to: "/notice"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Notice")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                class: "link",
                to: "/faq"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("FAQ")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.state.isAdmin)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_7, _toDisplayString(_ctx.$t("Admin")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.state.isAdmin)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
            _createElementVNode("li", null, [
              _createVNode(_component_router_link, {
                to: "/admin/firmware/list",
                class: "link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("Firmware Image Upload")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            false
              ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("VOC Management")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true),
            false
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("FAQ Management")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true),
            false
              ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Customer Statistics")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true),
            false
              ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    to: "/",
                    class: "link"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("Service Statistics")), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}